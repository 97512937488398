import {NavLink as Link} from 'react-router-dom';

import {Guide} from './components/Guide.jsx';
import {Markdown} from '../../components/Markdown.jsx';
import {Annotation} from '../../components/Annotation.jsx';

const content = `
### Development Environment

We're going to be using the Rust programming language and environment. If you don't have Rust installed, make sure to follow this [guide][9].

We will be working with WebAssembly, more on that in a later guide, so we'll also need to install a WebAssembly compile target.

~~~sh
rustup target add wasm32-unknown-unknown
~~~

Once that is out of the way, we can create a new Rust project.

~~~sh
cargo new --lib [project-name]
~~~

If all went well, you should see a new folder in the directory you executed the previous command. As a reference, I create a project named "hello" and this is how my folder looks like.

~~~
hello
|-- src
    |-- lib.rs
|-- Cargo.toml
|-- .gitignore
~~~

Alright, so we now have a Rust project, what's next?

### Manifest

Since I'm not familiar with Rust, I first wanted to understand what all the files were for. Starting with the default \`Cargo.toml\` (manifest) file. This is similar to a \`package.json\` file you would find in a typical JavaScript project. Let's take a closer look inside.

~~~toml:Cargo.toml
[package]
name = "project-name"
version = "0.1.0"
edition = "2021"
~~~

The \`name\` is simple, that's your project name. Next, \`version\` is also familiar, just that the way you use that field is defined more rigorously in the [documentation][1]. The \`edition\` field comes up blank for me... However, after going through the [docs][2] it becomes clear that it defines which non-backwards-compatible version of Rust you want to use. They give an example of older editions of Rust not having \`async\` and \`await\`, so if you want to use those you have to pick edition \`2018\` or greater.

OK, moving on...

### Library Type

~~~toml:Cargo.toml:6
[lib]
crate-type = ["cdylib"]
~~~

We have configure the \`crate-type\`. Blank.

Referring to the *amazing* [Cargo Book][3] it becomes clear that with the above we define a target for which we will be compiling the Rust code. In this case the section named \`[lib]\` defines settings for the "library" target. Not sure why a smart contract is a *library*, perhaps optimization?

Alright, so we're defining a *library*. Next, we provide one setting - \`crate-type\` with the \`["cdylib"]\` value, cool. This means that the product will be a *dynamic Rust library*, and from [what I read][4] the \`cdylib\` (terrible, awful name) stands for "dynamic library that can be linked into C/C++".

Point is, this section instructs the Rust compiler to compile a dynamic library that can be linked into C/C++.

### Soroban SDK and Features

~~~toml:Cargo.toml:9
[dependencies]
soroban-sdk = "0.2.1"

[dev_dependencies]
soroban-sdk = { version = "0.2.1", features = ["testutils"] }

[features]
testutils = ["soroban-sdk/testutils"]
~~~

Sweet! Back to familiar stuff - \`dependencies\` section defines external packages/libraries/dependencies that your project needs to compile and run. \`dev_dependencies\` seems like the same concept as \`devDependencies\` in \`package.json\` from the JavaScript world.

This curly-brace syntax allows to customize your dependency further, more than just selecting a specific version. With \`features = ["testutils"]\` we enable the \`testutils\` feature of the \`soroban-sdk\` dependency, as per [the docs][5].

Further down, we have a \`[features]\` section. Here, you define dependencies that certain features require. This works with the \`cfg\` expressions that conditionally include code to support the requested feature. So essentially you group your dependencies based on the features that depend on them.

In this case, we provide the \`testutils\` feature that depends on the \`soroban-sdk/testutils\` dependency. As far as I can gather, \`testutils\` is not a name that comes from Rust, but it is related to Rust [unit testing][6].

### \`release\` Profile

~~~toml:Cargo.toml:18
[profile.release]
opt-level = "z"
overflow-checks = true
debug = 0
debug-assertions = false
strip = "symbols"
panic = "abort"
codegen-units = 1
lto = true
~~~

Oh dear, this looks like a handful. First, what's a \`profile\`? The [docs][7] say that it is a way to modify compiler settings for our program, or smart contract in this case.

There are 4 built-in profiles: \`dev\`, \`release\`, \`test\`, and \`bench\`. Those are chosen automatically based on the \`cargo\` command you run.

So the \`[profile.release]\` section defines compiler settings for the \`release\` profile.

Let's go through the meaning of each setting.

~~~toml::18
# Optimization level = optimize for binary size, but also turn off loop vectorization
opt-level = "z" 
~~~

~~~toml::19
# Enables runtime integer overflow checks.
overflow-checks = true
~~~

~~~toml::20
# Amount of debug information included in the compiled binary = no debug information at all.
debug = 0
~~~

~~~toml::21
# Whether to enable debug_assert! macro.
debug-assertions = false
~~~

~~~toml::22
# Strips symbols from the binary.
strip = "symbols"
~~~

~~~toml::23
# The panic strategy will be to terminate the process upon panic.
panic = "abort"
~~~

~~~toml::24
# Controls how many "code generation units" will be used, 1 is the lowest amount. More units, slower code.
codegen-units = 1
~~~

~~~toml::25
# LLVM's link time optimization is enabled, produces better optimized code.
lto = true
~~~

These settings save size. Soroban contracts have a size limit of \`256KB\`.

### \`release-with-logs\` Profile

~~~toml:Cargo.toml:28
[profile.release-with-logs]
inherits = "release"
debug-assertions = true
~~~

This is a custom profile that has all the same settings as \`release\`, thus the \`inherits = "release"\` setting, except for the \`debug-assertions\` which are enabled. This is done to enable [logging][8].

### Final Manifest

Altogether, the \`Cargo.toml\` should look like this.

~~~toml:Cargo.toml
[package]
name = "project"
version = "0.1.0"
edition = "2021"

[lib]
crate-type = ["cdylib"]

[dependencies]
soroban-sdk = "0.2.1"

[dev_dependencies]
soroban-sdk = { version = "0.2.1", features = ["testutils"] }

[features]
testutils = ["soroban-sdk/testutils"]

[profile.release]
opt-level = "z"
overflow-checks = true
debug = 0
debug-assertions = false
strip = "symbols"
panic = "abort"
codegen-units = 1
lto = true

[profile.release-with-logs]
inherits = "release"
debug-assertions = true
~~~

[1]: https://doc.rust-lang.org/cargo/reference/manifest.html#the-version-field
[2]: https://doc.rust-lang.org/edition-guide/editions/index.html
[3]: https://doc.rust-lang.org/cargo/reference/cargo-targets.html
[4]: https://users.rust-lang.org/t/what-is-the-difference-between-dylib-and-cdylib/28847
[5]: https://doc.rust-lang.org/cargo/reference/features.html
[6]: https://doc.rust-lang.org/rust-by-example/testing/unit_testing.html
[7]: https://doc.rust-lang.org/cargo/reference/profiles.html
[8]: https://soroban.stellar.org/docs/examples/logging
[9]: https://www.rust-lang.org/tools/install
`;



function DevelopmentEnvironment() {
  return (
    <Guide>
      <Annotation>
      This guide was created by following the <a href="https://soroban.stellar.org/docs/tutorials/create-a-project" target="_blank" rel="noreferrer">Create a Project</a> tutorial from the official Soroban documentation.
      </Annotation>
      <p className="fs-6 text-muted">November 20, 2022</p>
      <Markdown>
        {content}
      </Markdown>
      
      <div className="card">
        <div className="card-body">
          <h6 className="card-subtitle mb-2 text-muted">Next guide</h6>
          <Link className="btn btn-primary" to="/guides/basic-contract">Basic Contract</Link>
        </div>
      </div>
    </Guide>
  );
}

export {DevelopmentEnvironment};