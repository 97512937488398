import {useState, useEffect} from 'react';

import {Documentation} from '../Documentation.jsx';
import {Code} from '../../../components/Code.jsx';

import {SorobanValue} from '../../../components/inputs';

function SorobanValueDocumentation(props) {
  const [value, setValue] = useState({
    type: 'scvObject',
    raw: {
      type: 'scoVec',
      raw: [
        {
          type: 'scvObject',
          raw: {type: 'scoAccountId', raw: 'GALJMHIIE7LQHDMRKYLBPJ7WNAQP3V3WJUE35J46U62MM5RM7OKXIWWW'}
        },
        {
          type: 'scvSymbol',
          raw: 'Howdy'
        },
        {
          type: 'scvStatic',
          raw: 'scsTrue'
        },
        {
          type: 'scvObject',
          raw: {
            type: 'scoVec',
            raw: [
              {
                type: 'scvSymbol',
                raw: 'Nested...'
              }
            ]
          }
        }
      ]
    }
    // raw: {
    //   type: 'scoAccountId',
    //   raw: 'GALJMHIIE7LQHDMRKYLBPJ7WNAQP3V3WJUE35J46U62MM5RM7OKXIWWW'
    // }
    // type: 'scvI32',
    // raw: '11111111111'
    // raw: '1010'
  });

  const onChange = (nextValue) => {
    setValue(nextValue);
  };

  return (
    <Documentation>
      <div className="col-12">
        <h3>
          Documentation
        </h3>
        <h4>
          <code>&lt;SorobanValue /&gt;</code>
        </h4>
        <div className="col-12 mb-5">
          <SorobanValue
            value={value}
            onChange={onChange}
          />
        </div>
        {value &&
          <div className="col-12 mb-2">
            <h5>Value</h5>
            <Code source={JSON.stringify(value, null, 2)} />
          </div>
        }
      </div>
    </Documentation>
  );
}

export {SorobanValueDocumentation};