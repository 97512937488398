import {useEffect, useState} from 'react';
import * as XDR from 'js-xdr';

import * as hex from '../../../../packages/utilities/hex.js';
import * as base32 from '../../../../packages/utilities/base32.js';
import * as base64 from '../../../../packages/utilities/base64.js';

import Stellar from '../../../../packages/soroban/xdr/stellar.js';

import {Keys} from '../../../../packages/stellar/Keys.js';

import {Code} from '../../../../components/Code.jsx';

const expressions = {
  moduleError: /Import \#(\d+) module=\"(\w+)\" error: (.*)/,
  functionError: /Import \#(\d+) module=\"(\w+)\" function=\"(\w+)\" error: (.*)/
};

function parseAssemblyImportObject(binary, callback, importObject = {}) {
  WebAssembly.instantiate(binary, importObject)
    .then((value) => {
      callback(value);
    })
    .catch(async (reason) => {
      const moduleError = expressions.moduleError.exec(reason.message);
      if (moduleError && moduleError.length === 4) {
        const [_, index, module, message] = moduleError;
        if (message === 'module is not an object or function') {
          importObject[module] = {};
          return parseAssemblyImportObject(binary, callback, importObject);
        }
      }

      const functionError = expressions.functionError.exec(reason.message);
      if (functionError && functionError.length === 5) {
        const [_, index, module, fn, message] = functionError;
        if (message === 'function import requires a callable') {
          importObject[module][fn] = () => {};
          return parseAssemblyImportObject(binary, callback, importObject);
        }
      }
    });
}
function parseAssembly(binary) {
  return new Promise((resolve, reject) => {
    parseAssemblyImportObject(binary, resolve);
  });
}

function parseRaw(xdr, parentType = null) {
  if (xdr instanceof XDR.Struct) {
    const buffer = {};
    for (const attributeName in xdr._attributes) {
      buffer[attributeName] = parse(xdr._attributes[attributeName]);
    }
    return buffer;
  } else if (xdr instanceof XDR.Union) {
    if (Number.isInteger(xdr._switch)) {
      return xdr._switch;
    } else if (xdr._value !== undefined) {
      const type = typeof xdr._arm  === 'string' ? xdr._arm : null;
      return parse(xdr._value, type);
    } else {
      return parse(xdr._switch);
    }
  } else if (xdr instanceof XDR.Enum) {
    return xdr.name;
    return {
      [xdr.name]: parse(xdr.value)
    };
  } else if (xdr instanceof XDR.Hyper) {
    const number = (
      (BigInt(xdr.high) << 32n) |
      (BigInt(xdr.low))
    );

    if (number === BigInt(Number.parseInt(number))) {
      return Number.parseInt(number);
    } else {
      return number;
    }
  } else if (xdr instanceof XDR.UnsignedHyper) {
    const number = (
      (BigInt(xdr.high) << 32n) |
      (BigInt(xdr.low))
    );

    if (number === BigInt(Number.parseInt(number))) {
      return Number.parseInt(number);
    } else {
      return number;
    }
  } else if (Number.isInteger(xdr)) {
    return Number.parseInt(xdr);
  } else if (xdr instanceof Uint8Array) {
    if (parentType === 'ed25519') {
      try {
        const keys = new Keys(xdr);
        return keys.formattedPublicKey;
      } catch (error) {}
    } else if (parentType === 'sym') {
      const symbol = new TextDecoder().decode(xdr);
      return symbol;
    }

    return hex.encode(xdr);
  } else if (xdr instanceof Array) {
    return xdr.map((xdr) => parse(xdr));
  } else {
    console.log(xdr);
    return null;
  }
}

function parse(xdr, type = null) {
  const parsed = parseRaw(xdr, type);
  return type ? {[type]: parsed} : parsed;
}

function Response(props) {
  const response = JSON.parse(
    JSON.stringify(props.response)
  );
  // console.log(response);

  if (response.status === 'error' && response.error.data) {
    if (response.error.data?.envelope_xdr) {
      response.error.data.envelope = parse(
        Stellar.TransactionEnvelope.fromXDR(response.error.data.envelope_xdr, 'base64'),
        'TransactionEnvelope'
      );
      delete response.error.data.envelope_xdr;
    }
    if (response.error.data?.result_xdr) {
      response.error.data.result = parse(
        Stellar.TransactionResult.fromXDR(response.error.data.result_xdr, 'base64'),
        'TransactionResult'
      );
      delete response.error.data.result_xdr;
    }
    if (response.error.data?.transaction?.result_xdr) {
      response.error.data.transaction.result = parse(
        Stellar.TransactionResult.fromXDR(response.error.data.transaction.result_xdr, 'base64'),
        'TransactionResult'
      );
      delete response.error.data.transaction.result_xdr;
    }
  }
  
  if (response.results) {
    response.results = response.results.map(
      (result) => parse(
        Stellar.ScVal.fromXDR(result.xdr, 'base64'),
        'ScVal'
      )
    );
  }

  if (response.xdr) {
    response.xdr = parse(
      Stellar.LedgerEntryData.fromXDR(response.xdr, 'base64'),
      'LedgerEntryData'
    );
  }
  
  if (response.footprint) {
    response.footprint = parse(
      Stellar.LedgerFootprint.fromXDR(response.footprint, 'base64'),
      'LedgerFootprint'
    );
  }

  const [code, setCode] = useState(null);

  const parseCode = async () => {
    const binary = hex.decode(response?.xdr?.LedgerEntryData?.contractCode?.code);
    const code = await parseAssembly(binary);

    const contractMetadataSection = WebAssembly.Module.customSections(code.module, 'contractenvmetav0');
    let metadata = {};
    for (const item of contractMetadataSection) {
      const entry = Stellar.ScEnvMetaEntry.fromXDR(item);
      const parsedEntry = parseRaw(entry);
      for (const key in parsedEntry) {
        metadata[key] = parsedEntry[key];
      }
    }

    const exportedFunctions = WebAssembly.Module.exports(code.module)
      .filter((item) => item.kind === 'function')
      .map((fn) => fn.name);

    setCode({
      exports: exportedFunctions,
      metadata
    });
  };

  useEffect(() => {
    if (response?.xdr?.LedgerEntryData?.contractCode?.code) {
      parseCode();
    }
  }, [response?.xdr?.LedgerEntryData?.contractCode?.code]);

  if (response instanceof Array) {
    for (const element of response) {
      if (element.topic instanceof Array) {
        const parsedTopic = [];
        for (const topic of element.topic) {
          parsedTopic.push(
            parse(
              Stellar.ScVal.fromXDR(topic, 'base64'),
              'ScVal'
            )
          );
        }
        element.topic = parsedTopic;
      }
      if (element.value && element.value.xdr) {
        element.value = parse(
          Stellar.ScVal.fromXDR(element.value.xdr, 'base64'),
          'ScVal'
        );
      }
    }
  }

  return (
    <>
      {code &&
        <>
          <h6>Parsed from contract binary</h6>
          <Code
            source={JSON.stringify(code, null, 2)}
          />
          <h6>Response</h6>
        </>
      }
      {response.error && typeof response.error === 'string' &&
        <>
          <h6>Parsed error</h6>
          <Code
            source={response.error}
          />
          <h6>Response</h6>
        </>
      }
      <Code
        source={response ? JSON.stringify(response, null, 2) : '...'}
      />
    </>
  );
}

export {Response};