import Stellar from '../../../soroban/xdr/stellar.js';
import * as base64 from '../../../utilities/base64.js';
import * as hex from '../../../utilities/hex.js';

/*
union HostFunction switch (HostFunctionType type)
{
case HOST_FUNCTION_TYPE_INVOKE_CONTRACT:
    SCVec invokeArgs;
case HOST_FUNCTION_TYPE_CREATE_CONTRACT:
    CreateContractArgs createContractArgs;
case HOST_FUNCTION_TYPE_INSTALL_CONTRACT_CODE:
    InstallContractCodeArgs installContractCodeArgs;
};

typedef SCVal SCVec<SCVAL_LIMIT>;

union SCVal switch (SCValType type)
{
case SCV_U63:
    int64 u63;
case SCV_U32:
    uint32 u32;
case SCV_I32:
    int32 i32;
case SCV_STATIC:
    SCStatic ic;
case SCV_OBJECT:
    SCObject* obj;
case SCV_SYMBOL:
    SCSymbol sym;
case SCV_BITSET:
    uint64 bits;
case SCV_STATUS:
    SCStatus status;
};
*/

class InvokeContract {
  constructor() {
    this._contractId = null;
    this._functionName = null;
    this._functionArguments = [];
    this._footprint = new Stellar.LedgerFootprint({
      readOnly: [],
      readWrite: []
    });
  }

  async serialized() {
    const invokeArguments = [
      Stellar.ScVal.scvObject(
        Stellar.ScObject.scoBytes(
          hex.decode(this._contractId)
        )
      ),
      Stellar.ScVal.scvSymbol(
        new TextEncoder().encode(this._functionName)
      ),
      ...this._functionArguments
    ];

    return new Stellar.Operation({
      body: Stellar.OperationBody.invokeHostFunction(
        new Stellar.InvokeHostFunctionOp({
          function: Stellar.HostFunction.hostFunctionTypeInvokeContract(
            invokeArguments
          ),
          footprint: this._footprint
        })
      )
    });
  }

  contractId(id) {
    this._contractId = id;
    return this;
  }
  functionName(name) {
    this._functionName = name;
    return this;
  }
  functionArgument(argument) {
    this._functionArguments.push(argument);
    return this;
  }
  functionArguments(list) {
    this._functionArguments = list;
    return this;
  }
  footprint(footprint) {
    if (footprint) {
      this._footprint = footprint;
    }
    return this;
  }
}

export {InvokeContract};